<template>
  <div>
    <Loading v-show="show" />

    <div class="breadcrumb-banner-area">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="breadcrumb-text breadcrumb-text1">
              <h1 class="text-center" id="postTitleId"></h1>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--Course Details Area Start-->
    <div class="course-details-area pt-20" style="background-color: #f6f6f6">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-12">
            <div class="course-details-content">
              <div class="single-course-details single-course-details1">
                <div class="row">
                  <div class="col-md-5">
                    <div class="overlay-effect">
                      <a><img loading="lazy" alt="" :src="imgLab" /></a>
                    </div>
                  </div>
                  <div class="col-md-7">
                    <div class="single-item-text">
                      <div class="course-text-content">
                        <p id="postSubTitleId"></p>
                        <p id="postDetailsId"></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--End of Course Details Area-->

    <!--Labs Images Area Start-->
    <div class="gallery-area pt-40 pb-50" v-show="showLabImage">
      <div class="section-title-wrapper">
        <div class="section-title" style="margin-bottom: 80px">
          <h3>{{ LabImage }}</h3>
        </div>
      </div>
      <div class="container">
        <div class="row">
          <div
            class="col-lg-4 col-md-6 mb-30"
            v-for="labsImage in LabsImage"
            :key="labsImage.postId"
          >
            <div class="gallery-img">
              <img
                loading="lazy"
                v-bind:src="
                  'https://api2.yuniv.net:444/images/post/' +
                  labsImage.postImage
                "
                alt=""
              />
              <div class="hover-effect">
                <div class="zoom-icon">
                  <a
                    class="popup-image"
                    v-bind:href="
                      'https://api2.yuniv.net:444/images/post/' +
                      labsImage.postImage
                    "
                    ><i class="fa fa-search-plus"></i
                  ></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--End of Labs Images Area-->
  </div>
</template>

<script>
import Loading from "./Loading";
import ArJson from "../../public/i18n/Ar.json";
import EnJson from "../../public/i18n/En.json";
import axios from "axios";
export default {
  components: {
    Loading,
  },
  data() {
    return {
      show: true,
      showLabImage: true,
      LabsImage: [],
      id: "",
      LabImage: "",
      imgLab: "",
      translate: "",
      ArTranslat: ArJson,
      EnTranslat: EnJson,
    };
  },
  methods: {
    getApis() {
      var self = this;
      self.id = this.$route.params.id;
      if (localStorage.getItem("lang") == "Ar") {
        self.translate = self.ArTranslat;
      } else {
        self.translate = self.EnTranslat;
      }
      self.LabImage = self.translate[0]["Home"]["LabImage"];
      var bodyFormData = new FormData();
      bodyFormData.append("check", "getPostById");
      bodyFormData.append("Lang", localStorage.getItem("lang"));
      bodyFormData.append("PostId", this.$route.params.id);
      bodyFormData.append("FbrnId", "");
      bodyFormData.append("Type", "Univ.labs");
      bodyFormData.append("ParentId", "NTROOT0");
      bodyFormData.append("PostName", "");
      bodyFormData.append("Pno", "1");
      axios({
        method: "post",
        url: "https://api2.yuniv.net:444/our_team/getPost",
        data: bodyFormData,
      }).then(function (response) {
        var arr = response.data;
        if (arr.length != 0) {
          self.imgLab =
            "https://api2.yuniv.net:444/images/post/" +
            response.data[0]["postImage"];
          document.getElementById("postTitleId").innerText =
            response.data[0]["postTitle"];
          document.getElementById("postSubTitleId").innerText =
            response.data[0]["postSubTitle"];
          document.getElementById("postDetailsId").innerHTML =
            response.data[0]["postDetails"];
        }

        var bodyFormData3 = new FormData();
        bodyFormData3.append("check", "getPost");
        bodyFormData3.append("Lang", localStorage.getItem("lang"));
        bodyFormData3.append("PostId", "");
        bodyFormData3.append("FbrnId", "");
        bodyFormData3.append("Type", "Univ.labsIMG");
        bodyFormData3.append("ParentId", self.id);
        bodyFormData3.append("PostName", "");
        bodyFormData3.append("Pno", "-1");
        axios({
          method: "post",
          url: "https://api2.yuniv.net:444/our_team/getPost",
          data: bodyFormData3,
        })
          .then(function (response) {
            var arr = response.data;
            if (arr.length != 0) {
              self.showLabImage = true;
              self.LabsImage = response.data;
            } else {
              self.showLabImage = false;
            }

            document.getElementById("VueMainJsNewTouch")?.remove();
            let recaptchaScript = document.createElement("script");
            recaptchaScript.setAttribute("src", "/js/main.js");
            recaptchaScript.id = "VueMainJsNewTouch";
            document.head.appendChild(recaptchaScript);

            setTimeout(() => {
              self.show = false;
              document
                .querySelectorAll("div.loader")
                .forEach(function (element) {
                  element.setAttribute("hidden", true);
                });
            }, 100);
          })
          .catch(function () {
            //   console.log("error", response);
          });
      });
    },
  },
  mounted() {
    window.scroll(0, 0);
    var self = this;
    self.id = this.$route.params.id;
    if (localStorage.getItem("lang") == null) {
      axios({
        method: "get",
        url: "https://api2.yuniv.net:444/languages/getLangIsMain",
      })
        .then(function (response) {
          self.lang = response.data[0]["LangId"];
          localStorage.setItem("lang", response.data[0]["LangId"]);
          if (response.data[0]["LangId"] == "Ar") {
            document.getElementById("langfilewebsites")?.remove();
            self.getApis();
          } else {
            self.translate = self.EnTranslat;
            let link1 = document.createElement("link");
            link1.setAttribute("rel", "stylesheet");
            link1.id = "langfilewebsites";
            link1.setAttribute("href", "../../../style-ltr.css");
            document.head.appendChild(link1);
            self.getApis();
          }
        })
        .catch(function () {
          //   console.log("error", response);
        });
    } else {
      if (localStorage.getItem("lang") == "Ar") {
        document.getElementById("langfilewebsites")?.remove();
        self.getApis();
      } else {
        let link1 = document.createElement("link");
        link1.setAttribute("rel", "stylesheet");
        link1.id = "langfilewebsites";
        link1.setAttribute("href", "../../../style-ltr.css");
        document.head.appendChild(link1);
        self.getApis();
      }
    }
  },
};
</script>
<style scoped>
.padding-top-area {
  padding-top: 50px;
  padding-bottom: 20px;
}
.padding-bottom-area {
  padding-top: 20;
  padding-bottom: 80px !important;
}
</style>
